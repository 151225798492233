<template>
  <div class="business__logo">
    <div class="business__logo-wrapper">
      <img
        :src="preview"
        alt="Vista previa del logo"
        class="img-fluid"
        width="150"
      />
    </div>
    <input type="file" class="hidden" ref="inputFile" @change="onChange" />
    <v-btn icon @click="$refs.inputFile.click()">
      <i class="fa fa-camera"></i>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    defaultImage: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      preview: "/images/image-placeholer.png",
    };
  },
  watch: {
    defaultImage(value) {
      this.preview = value;
    },
  },
  methods: {
    onChange(event) {
      const reader = new FileReader();
      const file = event.target.files[0];

      reader.onloadend = () => {
        this.preview = reader.result;
        this.$emit("onSetLogo", this.preview);
      };
      if (file) {
        this.$emit('onSetLogoFile', file);
        reader.readAsDataURL(file);
      } else {
        this.preview = "/images/image-placeholer.png";
        this.$emit("onSetLogo", null);
        this.$emit('onSetLogoFile', null);
      }
    },
  },
};
</script>
